import {
    formatConfigurableOptions as sourceFormatConfigurableOptions
} from 'SourceStore/Product/Product.reducer';
import {
    getIndexedAttributes, getIndexedConfigurableOptions, getIndexedReviews, getIndexedSingleVariant
} from 'Util/Product';

import {
    TOGGLE_OPTIONS,
    UPDATE_CURRENT_PRODUCT_IMAGE,
    UPDATE_PRODUCT_DETAILS, UPDATE_PRODUCT_PRICE, UPDATE_PRODUCT_VARIANTS
} from './Product.action';

export const formatConfigurableOptions = sourceFormatConfigurableOptions;

/** @namespace Bodypwa/Store/Product/Reducer/getInitialState */
export const getInitialState = () => ({
    product: {},
    formattedConfigurableOptions: {},
    products: {},
    showOptions: {}
});
/** @namespace Bodypwa/Store/Product/Reducer/ProductReducer */
export const ProductReducer = (
    state = getInitialState(),
    action
) => {
    const {
        products,
        product: originalProduct,
        product: {
            sku: activeSku
        }
    } = state;

    const { productAddtionalData } = action;
    const {
        sku: productWithUpdatesSku, price_range, variants: variantsWithUpdates = []
    } = productAddtionalData || {};

    switch (action.type) {
    case UPDATE_CURRENT_PRODUCT_IMAGE:
        return {
            ...state,
            products: {
                ...products,
                [productWithUpdatesSku]: products[productWithUpdatesSku] || productAddtionalData
            },
            product: products[productWithUpdatesSku] || productAddtionalData
        };
    case UPDATE_PRODUCT_DETAILS:
        const {
            product: {
                sku, reviews: initialReviews, options, attributes: initialAttributes,
                configurable_options: initialConfigurableOptions = []
            }, product
        } = action;

        const reviews = getIndexedReviews(initialReviews);
        const attributes = getIndexedAttributes(initialAttributes);
        const configurable_options = getIndexedConfigurableOptions(initialConfigurableOptions, attributes);
        if (sku === activeSku) {
            // eslint-disable-next-line no-unused-vars
            const { price_range, variants, ...rest } = product;
            const newProduct = {
                ...originalProduct,
                ...rest,
                attributes,
                configurable_options,
                reviews
            };

            return {
                ...state,
                products: {
                    ...products,
                    [sku]: newProduct
                },
                product: newProduct
            };
        }

        const initialProduct = {
            ...product,
            reviews,
            ...(products[sku] || {}),
            attributes,
            configurable_options,
            options: options || []
        };

        return {
            ...state,
            product: initialProduct,
            products: {
                ...products,
                [sku]: initialProduct
            }
        };
    case UPDATE_PRODUCT_VARIANTS:
        const {
            variants: initialVariants = []
        } = products[productWithUpdatesSku] || {};
        const mergedVariants = [];

        variantsWithUpdates.forEach((item) => {
            const curentProduct = item.product || item;
            const originalItem = initialVariants.find(
                (ogItem) => ogItem.product?.sku === curentProduct.sku || ogItem.sku === curentProduct.sku
            ) || {};

            mergedVariants.push({
                product: {
                    ...originalItem,
                    ...curentProduct
                }
            });
        });
        if (!mergedVariants.length) {
            return state;
        }
        const productWithVariants = {
            ...(products[productWithUpdatesSku] || {}),
            ...productAddtionalData,
            variants: getIndexedSingleVariant(mergedVariants, productWithUpdatesSku)
        };

        return {
            ...state,
            products: {
                ...products,
                [productWithUpdatesSku]: productWithVariants
            },
            product: productWithVariants
        };
    case UPDATE_PRODUCT_PRICE:
        const {
            variants: originalVariants = [],
            price_range: originalPriceRange
        } = products[productWithUpdatesSku] || {};
        const mergedProducts = [];

        variantsWithUpdates.forEach((item) => {
            const curentProduct = item.product || item;
            const originalItem = originalVariants.find(
                (ogItem) => ogItem.product?.sku === curentProduct.sku || ogItem.sku === curentProduct.sku
            ) || {};

            mergedProducts.push({
                ...originalItem,
                ...curentProduct
            });
        });

        const newPriceRange = price_range?.minimum_price ? price_range : originalPriceRange;

        const productWithPrice = {
            ...(products[productWithUpdatesSku] || {}),
            variants: mergedProducts,
            price_range: newPriceRange
        };

        return {
            ...state,
            products: {
                ...products,
                [productWithUpdatesSku]: productWithPrice
            },
            product: productWithPrice
        };
    case TOGGLE_OPTIONS:
        const { showOptions } = action;

        return {
            ...state,
            showOptions: {
                ...state.showOptions,
                ...showOptions
            }
        };
    default:
        return state;
    }
};

export default ProductReducer;
