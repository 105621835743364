export const UPDATE_SEARCH_BAR = 'UPDATE_SEARCH_BAR';
export const UPDATE_SEARCH_LOAD_STATUS = 'UPDATE_SEARCH_LOAD_STATUS';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const CLEAR_REDIRECT_URL = 'CLEAR_REDIRECT_URL';
export const UPDATE_IS_URL_REDIRECT_LOADING = 'UPDATE_IS_URL_REDIRECT_LOADING';
/** @namespace Bodypwa/Store/SearchBar/Action/updateSearchBar */
export const updateSearchBar = (result) => ({
    type: UPDATE_SEARCH_BAR,
    result
});

/** @namespace Bodypwa/Store/SearchBar/Action/updateLoadStatus */
export const updateLoadStatus = (status) => ({
    type: UPDATE_SEARCH_LOAD_STATUS,
    isLoading: status
});

/** @namespace Bodypwa/Store/SearchBar/Action/clearSearchResults */
export const clearSearchResults = () => ({
    type: CLEAR_SEARCH_RESULTS
});

/** @namespace Bodypwa/Store/SearchBar/Action/clearRedirectUrl */
export const clearRedirectUrl = () => ({
    type: CLEAR_REDIRECT_URL
});

/** @namespace Bodypwa/Store/SearchBar/Action/updateIsUrlRedirectLoading */
export const updateIsUrlRedirectLoading = (isUrlRedirectLoading) => ({
    type: UPDATE_IS_URL_REDIRECT_LOADING,
    isUrlRedirectLoading
});
