/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
export const OPEN_SIDE_MENU = 'OPEN_SIDE_MENU';
export const CLOSE_SIDE_MENU = 'CLOSE_SIDE_MENU';
export const HIDE_MAIN_LEVEL = 'HIDE_MAIN_LEVEL';
export const SHOW_MAIN_LEVEL = 'SHOW_MAIN_LEVEL';
export const SET_PROMO_IMAGE = 'SET_PROMO_IMAGE';
export const GET_PROMO_IMAGE = 'GET_PROMO_IMAGE';

/** @namespace Scandiweb/NavigationExtension/Store/SideMenu/Action/openSideMenu */
export const openSideMenu = () => ({
    type: OPEN_SIDE_MENU
});

/** @namespace Scandiweb/NavigationExtension/Store/SideMenu/Action/closeSideMenu */
export const closeSideMenu = () => ({
    type: CLOSE_SIDE_MENU
});

/** @namespace Scandiweb/NavigationExtension/Store/SideMenu/Action/hideMainLevel */
export const hideMainLevel = () => ({
    type: HIDE_MAIN_LEVEL
});

/** @namespace Scandiweb/NavigationExtension/Store/SideMenu/Action/showMainLevel */
export const showMainLevel = () => ({
    type: SHOW_MAIN_LEVEL
});

/** @namespace Scandiweb/NavigationExtension/Store/SideMenu/Action/setPromoImage */
export const setPromoImage = (promoImage) => ({
    type: SET_PROMO_IMAGE,
    promoImage
});

/** @namespace Scandiweb/NavigationExtension/Store/SideMenu/Action/getPromoImage */
export const getPromoImage = () => ({
    type: GET_PROMO_IMAGE
});
