import {
    CLEAR_REDIRECT_URL,
    CLEAR_SEARCH_RESULTS,
    UPDATE_IS_URL_REDIRECT_LOADING,
    UPDATE_SEARCH_BAR,
    UPDATE_SEARCH_LOAD_STATUS
} from '@scandipwa/scandipwa/src/store/SearchBar/SearchBar.action';

import {
    getInitialState as getSourceInitialState
} from 'SourceStore/SearchBar/SearchBar.reducer';
import { getIndexedProducts } from 'Util/Product';

/** @namespace Bodypwa/Store/SearchBar/Reducer/getInitialState */
export const getInitialState = () => ({
    ...getSourceInitialState(),
    productsInSearch: [],
    isLoading: true,
    redirectUrl: '',
    isUrlRedirectLoading: false
});
/** @namespace Bodypwa/Store/SearchBar/Reducer/SearchBarReducer */
export const SearchBarReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_SEARCH_BAR:
        const { result: { xsearchProducts: { items: initialItems, total_count, redirectUrl } } } = action;

        return {
            ...state,
            productsInSearch: getIndexedProducts(initialItems),
            redirectUrl,
            totalResults: total_count
        };

    case UPDATE_SEARCH_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    case CLEAR_SEARCH_RESULTS:
        return {
            ...state,
            productsInSearch: getInitialState().productsInSearch
        };

    case CLEAR_REDIRECT_URL:
        return {
            ...state,
            redirectUrl: ''
        };

    case UPDATE_IS_URL_REDIRECT_LOADING:
        const { isUrlRedirectLoading } = action;
        return {
            ...state,
            isUrlRedirectLoading
        };

    default:
        return state;
    }
};

export default SearchBarReducer;
