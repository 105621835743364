import { APP_UPDATED, BODYAPP_VERSION } from 'Component/NewVersionPopup/NewVersionPopup.config';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
/** @namespace Bodypwa/Util/Cache/VersionCheck/checkAppVersionFromHeader */
export const checkAppVersionFromHeader = () => {
    const req = new XMLHttpRequest();
    req.open('GET', document.location + new Date().getTime(), true);

    req.onload = () => {
        const headers = req.getAllResponseHeaders().split('\n');
        const versionHeader = headers?.filter((header) => header?.includes(BODYAPP_VERSION));
        if (versionHeader.length) {
            const version = versionHeader[0].replace(`${BODYAPP_VERSION}: `, '').replace('\r', '');
            const currentVersion = window.localStorage.getItem(BODYAPP_VERSION);
            if (currentVersion && version !== currentVersion) {
                window.localStorage.setItem(BODYAPP_VERSION, version);
                BrowserDatabase.setItem(true, APP_UPDATED);
                window.location.reload();
            }
            window.localStorage.setItem(BODYAPP_VERSION, version);
        }
    };
    req.send(null);
};
