export const SET_MENU = 'SET_MENU';

export const INCREMENT_ROUTE_COUNTER = 'INCREMENT_ROUTE_COUNTER';
export const RESET_ROUTE_COUNTER = 'RESET_ROUTE_COUNTER';

export const SET_TIME_START = 'SET_TIME_START';
export const RESET_START_TIME = 'RESET_START_TIME';

/** @namespace Bodypwa/Store/Menu/Action/setMenu */
export const setMenu = (menu) => ({
    type: SET_MENU,
    menu
});

/** @namespace Bodypwa/Store/Menu/Action/incrementRouteCounter */
export const incrementRouteCounter = () => ({
    type: INCREMENT_ROUTE_COUNTER
});

/** @namespace Bodypwa/Store/Menu/Action/resetRouteCounter */
export const resetRouteCounter = () => ({
    type: RESET_ROUTE_COUNTER
});

/** @namespace Bodypwa/Store/Menu/Action/setTimeStart */
export const setTimeStart = (timeStart) => ({
    type: SET_TIME_START,
    timeStart
});
