// import { Field } from 'Util/Query';
import { Field } from 'Util/Query';

/** @namespace Bodypwa/Query/ConfiguredCountries/Query */
export class ConfiguredCountriesQuery {
    getQuery() {
        return new Field('configuredCountries').addField(this._getCountryField());
    }

    _getCountryField() {
        return new Field('countries')
            .addFieldList(this._getCountriesField());
    }

    _getCountriesField() {
        return [
            'code',
            'currency',
            'label',
            'locale',
            'names',
            'url',
            'hreflang',
            'store_id'
        ];
    }
}

export default new ConfiguredCountriesQuery();
