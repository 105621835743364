/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable no-console */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { FIREBASE_EDPOINT } from 'Route/SomethingWentWrong/SomethingWentWrong.config';
import { getAuthorizationToken } from 'Util/Auth';
import { getCurrency } from 'Util/Currency';

import { hash } from './Hash';

export const GRAPHQL_URI = '/graphql';
export const WINDOW_ID = 'WINDOW_ID';

/** @namespace Bodypwa/Util/Request/getWindowId */
export const getWindowId = () => {
    const result = sessionStorage.getItem(WINDOW_ID);

    if (!result) {
        const id = Date.now();
        sessionStorage.setItem(WINDOW_ID, id);

        return id;
    }

    return result;
};

/** @namespace Bodypwa/Util/Request/getStoreCodePath */
export const getStoreCodePath = () => {
    const path = location.pathname;
    // eslint-disable-next-line no-undef
    const firstPathPart = path.split('/')[1];

    if (window.storeList.includes(firstPathPart)) {
        return `/${ firstPathPart }`;
    }

    return '';
};

/** @namespace Bodypwa/Util/Request/getGraphqlEndpoint */
export const getGraphqlEndpoint = () => getStoreCodePath().concat(GRAPHQL_URI);

/**
 * Append authorization token to header object
 * @param {Object} headers
 * @returns {Object} Headers with appended authorization
 * @namespace Bodypwa/Util/Request/appendTokenToHeaders */
export const appendTokenToHeaders = (headers) => {
    const token = getAuthorizationToken();

    return {
        ...headers,
        Authorization: token ? `Bearer ${token}` : ''
    };
};

/**
 *
 * @param {String} query Request body
 * @param {Object} variables Request variables
 * @param {String} url GraphQL url
 * @returns {*}
 * @namespace Bodypwa/Util/Request/formatURI */
export const formatURI = (query, variables, url) => {
    // eslint-disable-next-line no-param-reassign
    variables._currency = getCurrency();

    const stringifyVariables = Object.keys(variables).reduce(
        (acc, variable) => [...acc, `${ variable }=${ JSON.stringify(variables[variable]) }`],
        [`?hash=${ hash(query) }`]
    );

    return `${ url }${ stringifyVariables.join('&') }`;
};

/**
 *
 * @param {String} uri
 * @param {String} name
 * @returns {Promise<Response>}
 * @namespace Bodypwa/Util/Request/getFetch */
export const getFetch = (uri, name) => fetch(uri,
    {
        method: 'GET',
        headers: appendTokenToHeaders({
            'Content-Type': 'application/json',
            'Application-Model': `${ name }_${ getWindowId() }`,
            Accept: 'application/json'
        })
    });

/**
 *
 * @param {String} graphQlURI
 * @param {{}} query Request body
 * @param {Int} cacheTTL
 * @namespace Bodypwa/Util/Request/putPersistedQuery */
export const putPersistedQuery = (graphQlURI, query, cacheTTL) => fetch(`${ graphQlURI }?hash=${ hash(query) }`,
    {
        method: 'PUT',
        body: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json',
            'SW-Cache-Age': cacheTTL
        }
    });

/**
 *
 * @param {String} graphQlURI
 * @param {String} queryObject
 * @param {String} name
 * @returns {Promise<Response>}
 * @namespace Bodypwa/Util/Request/postFetch */
export const postFetch = (graphQlURI, query, variables, signal = null, headers = {}) => fetch(graphQlURI,
    {
        method: 'POST',
        body: JSON.stringify({ query, variables }),
        headers: appendTokenToHeaders({
            ...headers,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }),
        signal
    });

/**
 * Checks for errors in response, if they exist, rejects promise
 * @param  {Object} res Response from GraphQL endpoint
 * @return {Promise<Object>} Handled GraphqlQL results promise
 * @namespace Bodypwa/Util/Request/checkForErrors */
export const checkForErrors = (res) => new Promise((resolve, reject) => {
    const { errors, data } = res;

    return errors ? reject(errors) : resolve(data);
});

/**
 * Handle connection errors
 * @param  {any} err Error from fetch
 * @return {void} Simply console error
 * @namespace Bodypwa/Util/Request/handleConnectionError */
export const handleConnectionError = (err) => console.error(err); // TODO: Add to logs pool
/** @namespace Bodypwa/Util/Request/detectBrowser */
export const detectBrowser = () => {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) {
            return { name: tem.slice(1).join(' ').replace('OPR', 'Opera'), version: tem[2] };
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    // eslint-disable-next-line no-cond-assign
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }

    return {
        name: M[0],
        version: M[1]
    };
};
/** @namespace Bodypwa/Util/Request/handleRestrictedAccess */
export const handleRestrictedAccess = (body) => {
    const docContent = document.getElementById('err-content');
    if (!docContent) {
        const styleElement = document.createElement('style');
        document.head.appendChild(styleElement);
        styleElement.textContent = '#html-body { display:none }';
        const content = document.createElement('div');
        content.setAttribute('id', 'err-content');
        content.innerHTML = body;
        document.documentElement.appendChild(content);
        const data = {
            baseUrl: window.location.origin,
            currentDate: new Date(Date.now()).toString(),
            browser: detectBrowser(),
            stackPath: true,
            windowSession: sessionStorage.getItem('WINDOW_ID')
        };

        fetch(FIREBASE_EDPOINT, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });
    } else {
        docContent.innerHTML = body;
    }
};
/**
 * Parse response and check wether it contains errors
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace Bodypwa/Util/Request/parseResponse */
export const parseResponse = (promise) => new Promise((resolve, reject) => {
    promise.then(
        /** @namespace Bodypwa/Util/Request/parseResponse/Promise/promise/then */
        (res) => {
            // eslint-disable-next-line no-magic-numbers
            if (res.status === 403) {
                return res.text().then(
                    /** @namespace Bodypwa/Util/Request/parseResponse/Promise/promise/then/text/then */
                    (body) => {
                        handleRestrictedAccess(body);
                        reject(res);
                    }
                );
            }

            return res.json().then(
                /** @namespace Bodypwa/Util/Request/parseResponse/Promise/promise/then/json/then/resolve */
                (res) => resolve(checkForErrors(res)),
                /** @namespace Bodypwa/Util/Request/parseResponse/Promise/promise/then/json/then/catch */
                () => {
                    handleConnectionError('Can not transform JSON!');
                    return reject();
                }
            );
        },
        /** @namespace Bodypwa/Util/Request/parseResponse/Promise/promise/then/catch */
        (err) => {
            handleConnectionError('Can not establish connection!');
            return reject(err);
        }
    );
});

export const HTTP_503_SERVICE_UNAVAILABLE = 503;
export const HTTP_410_GONE = 410;
export const HTTP_201_CREATED = 201;

/**
 * Make GET request to endpoint (via ServiceWorker)
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @param  {String} name Name of model for ServiceWorker to send BroadCasts updates to
 * @param  {Number} cacheTTL Cache TTL (in seconds) for ServiceWorker to cache responses
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace Bodypwa/Util/Request/executeGet */
export const executeGet = (queryObject, name, cacheTTL) => {
    const { query, variables } = queryObject;
    const uri = formatURI(query, variables, getGraphqlEndpoint());

    return parseResponse(new Promise((resolve, reject) => {
        getFetch(uri, name).then(
            /** @namespace Bodypwa/Util/Request/executeGet/parseResponse/getFetch/then */
            (res) => {
                // eslint-disable-next-line no-magic-numbers
                if (res.status === 403) {
                    res.text().then(
                    /** @namespace Bodypwa/Util/Request/executeGet/parseResponse/getFetch/then/text/then */
                        (body) => {
                            handleRestrictedAccess(body);
                            reject(res);
                        }
                    );
                }
                if (res.status === HTTP_410_GONE) {
                    putPersistedQuery(getGraphqlEndpoint(), query, cacheTTL).then(
                        /** @namespace Bodypwa/Util/Request/executeGet/parseResponse/getFetch/then/putPersistedQuery/then */
                        (putResponse) => {
                            if (putResponse.status === HTTP_201_CREATED) {
                                getFetch(uri, name).then(
                                    /** @namespace Bodypwa/Util/Request/executeGet/parseResponse/getFetch/then/putPersistedQuery/then/getFetch/then/resolve */
                                    (res) => resolve(res)
                                );
                            }
                        }
                    );
                } else if (res.status === HTTP_503_SERVICE_UNAVAILABLE) {
                    reject(res);
                } else {
                    resolve(res);
                }
            }
        );
    }));
};

/**
 * Make POST request to endpoint
 * @param  {{}} queryObject prepared with `prepareDocument()` from `Util/Query` request body object
 * @return {Promise<Request>} Fetch promise to GraphQL endpoint
 * @namespace Bodypwa/Util/Request/executePost */
export const executePost = (queryObject, signal, headers = {}) => {
    const { query, variables } = queryObject;
    return parseResponse(postFetch(getGraphqlEndpoint(), query, variables, signal, headers));
};

/**
 * Listen to the BroadCast connection
 * @param  {String} name Name of model for ServiceWorker to send BroadCasts updates to
 * @return {Promise<any>} Broadcast message promise
 * @namespace Bodypwa/Util/Request/listenForBroadCast */
export const listenForBroadCast = (name) => new Promise((resolve) => {
    const { BroadcastChannel } = window;
    const windowId = getWindowId();

    if (BroadcastChannel) {
        const bc = new BroadcastChannel(`${ name }_${ windowId }`);
        bc.onmessage = (update) => {
            const { data: { payload: body } } = update;
            resolve(checkForErrors(body));
        };
    }
});

/** @namespace Bodypwa/Util/Request/debounce */
export const debounce = (callback, delay) => {
    // eslint-disable-next-line fp/no-let
    let timeout;

    return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), delay);
    };
};

/** @namespace Bodypwa/Util/Request */
export class Debouncer {
    timeout;

    handler = () => {};

    startDebounce = (callback, delay) => (...args) => {
        const context = this;
        clearTimeout(this.timeout);
        this.handler = () => callback.apply(context, args);
        this.timeout = setTimeout(this.handler, delay);
    };

    cancelDebounce = () => {
        clearTimeout(this.timeout);
    };

    cancelDebounceAndExecuteImmediately = () => {
        clearTimeout(this.timeout);
        this.handler();
    };
}
