import {
    UPDATE_INFO_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_INFO,
    updateInfoLoadStatus,
    updateProductListInfo
} from 'SourceStore/ProductListInfo/ProductListInfo.action';

export {
    UPDATE_PRODUCT_LIST_INFO,
    UPDATE_INFO_LOAD_STATUS,
    updateProductListInfo,
    updateInfoLoadStatus
};

export const UPDATE_ACTIVE_FILTER = 'UPDATE_ACTIVE_FILTER';
export const REMOVE_ACTIVE_FILTER = 'REMOVE_ACTIVE_FILTER';
/** @namespace Bodypwa/Store/ProductListInfo/Action/updateActiveFilter */
export const updateActiveFilter = (activeFilter) => ({
    type: UPDATE_ACTIVE_FILTER,
    activeFilter
});

/** @namespace Bodypwa/Store/ProductListInfo/Action/removeActiveFilter */
export const removeActiveFilter = (attribute_code, value_string) => ({
    type: REMOVE_ACTIVE_FILTER,
    attribute_code,
    value_string
});
