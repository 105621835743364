import { Field, prepareMutation } from 'Util/Query';
import { executePost } from 'Util/Request/Request';
import getStore from 'Util/Store';

/** @namespace Bodypwa/Util/Request/Mutation/fetchMutation */
// eslint-disable-next-line import/prefer-default-export
export const fetchMutation = (rawMutations, signal, useCaptcha) => {
    const queries = rawMutations instanceof Field ? [rawMutations] : rawMutations;
    const googleRecaptcha = getStore().getState().ConfigReducer.google_recaptcha ?? { site_key: null };
    const {
        site_key
    } = googleRecaptcha;

    if (useCaptcha && site_key && window.grecaptcha) {
        return new Promise((resolve, reject) => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(
                    site_key,
                    { action: 'submit' }
                ).then(
                    /** @namespace Bodypwa/Util/Request/Mutation/fetchMutation/ready/then/catch/execute/then */
                    (token) => {
                        resolve(executePost(prepareMutation(queries, true), signal, { 'X-ReCaptcha': token }));
                    }
                ).catch(reject);
            });
        });
    }

    return executePost(prepareMutation(queries, true), signal);
};
