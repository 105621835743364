export const FIREBASE_ENDPOINT_GTM = 'https://loggtmbm2-default-rtdb.europe-west1.firebasedatabase.app/.json';
export const LOGGED_EVENT = 'GTM_LOGGED_EVENT';
export const ENTRY_POINT_ONE = 'ENTRY_POINT_ONE';
export const ENTRY_POINT_TWO = 'ENTRY_POINT_TWO';
export const ENTRY_POINT_THREE = 'ENTRY_POINT_THREE';
export const ENTRY_POINT_SUCCES_PAGE = 'ENTRY_POINT_SUCCES_PAGE';
export const ENTRY_POINT_ARRAY = [
    ENTRY_POINT_ONE,
    ENTRY_POINT_TWO,
    ENTRY_POINT_THREE,
    ENTRY_POINT_SUCCES_PAGE
];
