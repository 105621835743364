export const SET_FOOTER_REF = 'SET_FOOTER_REF';
export const SET_TOP_PANEL_POSITION = 'SET_TOP_PANEL_POSITION';
export const SET_SCROLL_TO_ELEMENT = 'SET_SCROLL_TO_ELEMENT';
export const SET_WINDOW_HEIGHT = 'SET_WINDOW_HEIGHT';
export const SET_HEADER_ELEMENT = 'SET_HEADER_ELEMENT';
export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';
export const SET_HEADER_HIDDEN = 'SET_HEADER_HIDDEN';
export const SET_HEADER_POSITION = 'SET_HEADER_POSITION';
export const SET_HEADER_PLACEHOLDER_HEIGHT = 'SET_HEADER_PLACEHOLDER_HEIGHT';
export const SET_HEADER_TOP_REF = 'SET_HEADER_TOP_REF';
export const SET_FILTER_ELEMENT = 'SET_FILTER_ELEMENT';
export const SET_FILTER_TOP_REF = 'SET_FILTER_TOP_REF';
export const SET_SCROLL_TO_POSITION = 'SET_SCROLL_TO_POSITION';
export const UPDATE_SCROLL = 'UPDATE_SCROLL';
export const SET_FILTERS_HEIGHT = 'SET_FILTERS_HEIGHT';
export const RESET_HEADER = 'RESET_HEADER';
/** @namespace Bodypwa/Store/Ui/Action/updateScroll */
export const updateScroll = (updateScroll) => ({
    type: UPDATE_SCROLL,
    updateScroll
});
/** @namespace Bodypwa/Store/Ui/Action/setScrollToPosition */
export const setScrollToPosition = (scrollToPosition) => ({
    type: SET_SCROLL_TO_POSITION,
    scrollToPosition
});

/** @namespace Bodypwa/Store/Ui/Action/setHeaderHeight */
export const setHeaderHeight = (headerHeight) => ({
    type: SET_HEADER_HEIGHT,
    headerHeight
});

/** @namespace Bodypwa/Store/Ui/Action/setFooterRef */
export const setFooterRef = (footerRef) => ({
    type: SET_FOOTER_REF,
    footerRef
});

/** @namespace Bodypwa/Store/Ui/Action/setTopPannelPosition */
export const setTopPannelPosition = (topPannelPosition) => ({
    type: SET_TOP_PANEL_POSITION,
    topPannelPosition
});

/** @namespace Bodypwa/Store/Ui/Action/setScrollToElement */
export const setScrollToElement = (scrollToElement) => ({
    type: SET_SCROLL_TO_ELEMENT,
    scrollToElement
});

/** @namespace Bodypwa/Store/Ui/Action/setWindowHeight */
export const setWindowHeight = (windowHeight) => ({
    type: SET_WINDOW_HEIGHT,
    windowHeight
});

/** @namespace Bodypwa/Store/Ui/Action/setHeaderHidden */
export const setHeaderHidden = (headerHidden) => ({
    type: SET_HEADER_HIDDEN,
    headerHidden
});

/** @namespace Bodypwa/Store/Ui/Action/setHeaderPosition */
export const setHeaderPosition = (headerPosition) => ({
    type: SET_HEADER_POSITION,
    headerPosition
});

/** @namespace Bodypwa/Store/Ui/Action/setHeaderPlaceholderHeight */
export const setHeaderPlaceholderHeight = (headerPlaceholderHeight) => ({
    type: SET_HEADER_PLACEHOLDER_HEIGHT,
    headerPlaceholderHeight
});

/** @namespace Bodypwa/Store/Ui/Action/setFiltersHeight */
export const setFiltersHeight = (filtersHeight) => ({
    type: SET_FILTERS_HEIGHT,
    filtersHeight
});

/** @namespace Bodypwa/Store/Ui/Action/setHeaderElement */
export const setHeaderElement = (headerElement) => ({
    type: SET_HEADER_ELEMENT,
    headerElement
});

/** @namespace Bodypwa/Store/Ui/Action/setHeaderTopRef */
export const setHeaderTopRef = (headerTopRef) => ({
    type: SET_HEADER_TOP_REF,
    headerTopRef
});

/** @namespace Bodypwa/Store/Ui/Action/setFilterElement */
export const setFilterElement = (filterElement) => ({
    type: SET_FILTER_ELEMENT,
    filterElement
});

/** @namespace Bodypwa/Store/Ui/Action/setFilterTopRef */
export const setFilterTopRef = (filterTopRef) => ({
    type: SET_FILTER_TOP_REF,
    filterTopRef
});

/** @namespace Bodypwa/Store/Ui/Action/resetHeader */
export const resetHeader = (isResetHeader) => ({
    type: RESET_HEADER,
    isResetHeader
});
