/* eslint-disable no-await-in-loop */
/** @namespace Bodypwa/Util/DeleteFromCache/deleteFromCache */

export function deleteFromCache(url) {
    return new Promise((resolve) => {
        // Delete from browser cache
        if ('caches' in window) {
            caches
                .keys()
                .then(
                    /** @namespace Bodypwa/Util/DeleteFromCache/deleteFromCache/then/then/then/then/keys/then */
                    (cacheNames) => {
                        const deletePromises = cacheNames.map((cacheName) => caches.open(cacheName).then(
                            /** @namespace Bodypwa/Util/DeleteFromCache/deleteFromCache/then/then/then/then/keys/then/deletePromises/cacheNames/map/open/then */
                            (cache) => cache.match(url).then(
                                /** @namespace Bodypwa/Util/DeleteFromCache/deleteFromCache/then/then/then/then/keys/then/deletePromises/cacheNames/map/open/then/match/then */
                                (response) => {
                                    if (response) {
                                        return cache.delete(url).then(
                                            /** @namespace Bodypwa/Util/DeleteFromCache/deleteFromCache/then/then/then/then/keys/then/deletePromises/cacheNames/map/open/then/match/then/delete/then */
                                            () => {
                                                console.log(`Deleted ${url} from ${cacheName}`);
                                            }
                                        );
                                    }

                                    return Promise.resolve();
                                }
                            )
                        ));

                        return Promise.all(deletePromises);
                    }
                )
                .then(
                    /** @namespace Bodypwa/Util/DeleteFromCache/deleteFromCache/then/then/then/then */
                    () => {
                    // Delete from service worker cache
                        if ('serviceWorker' in navigator) {
                            return navigator.serviceWorker.ready.then(
                                /** @namespace Bodypwa/Util/DeleteFromCache/deleteFromCache/then/then/then/then/then */
                                (registration) => {
                                    if (registration && registration.active) {
                                        registration.active.postMessage({ type: 'delete-cache', url });
                                    }
                                }
                            );
                        }

                        return Promise.resolve();
                    }
                )
                .then(resolve);
        } else {
            resolve();
        }
    });
}
