import {
    getInitialState as sourceGetInitialState,
    NotificationReducer as sourceNotificationReducer
} from 'SourceStore/Notification/Notification.reducer';

import { SHOW_NOTIFICATION } from './Notification.action';

export const getInitialState = sourceGetInitialState;

/** @namespace Bodypwa/Store/Notification/Reducer/NotificationReducer */
export const NotificationReducer = (
    state = getInitialState(),
    action
) => {
    const notifications = { ...state.notifications };

    switch (action.type) {
    case SHOW_NOTIFICATION:
        const { msgType, msgText, msgDebug } = action;

        const addNotification = !Object.values(notifications).filter(
            (notification) => notification.msgText.value === msgText
        ).length;

        if (addNotification) {
            notifications[Date.now()] = { msgType, msgText: __(msgText), msgDebug };
        }

        return {
            ...state,
            notifications
        };

    default:
        return sourceNotificationReducer(state, action);
    }
};

export default NotificationReducer;
