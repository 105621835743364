/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import CartQuery from '@scandipwa/scandipwa/src/query/Cart.query';
import MyAccountQuery from '@scandipwa/scandipwa/src/query/MyAccount.query';
import { isSignedIn } from '@scandipwa/scandipwa/src/util/Auth';

import { Field } from 'Util/Query';

/** @namespace Paypal/Query/PayPal/Query */
export class PayPalQuery {
    getCreatePaypalExpressTokenMutation(input) {
        return new Field('s_createPaypalExpressToken')
            .addArgument('input', 'S_PaypalExpressTokenInput!', input)
            .addFieldList(this._getTokenFields())
            .setAlias('paypalExpress');
    }

    _getTokenFields() {
        return ['token'];
    }

    getPaypalShippingInformation(billingToken, guest_cart_id) {
        return new Field('getPaypalShippingInformation')
            .addFieldList(this.getPaypalShippingInfoFields())
            .addArgument('billingToken', 'String', billingToken)
            .addArgument('guest_cart_id', 'String', guest_cart_id);
    }

    getPaypalShippingInfoFields() {
        const fields = [
            ...MyAccountQuery._getAddressFields(),
            'email'
        ];

        return [
            new Field('shipping_address').addFieldList(fields),
            new Field('billing_address').addFieldList(fields)
        ];
    }

    /**
     *
     * @param guest_cart_id
     * @param shipping_method
     * @returns {*}
     */
    getSetShippingMethodOnCartMutation({ guest_cart_id, shipping_method }) {
        const query = new Field('setShippingMethodOnCart')
            .addArgument('shipping_methods', '[ShippingMethodInput]!', [shipping_method])
            .addFieldList(CartQuery._getCartTotalsFields());

        if (!isSignedIn()) {
            query.addArgument('guestCartId', 'String', guest_cart_id);
        }

        return query;
    }
}

export default new PayPalQuery();
