import ConfiguredCountries from 'Query/ConfiguredCountries.query';
import SimulateShippingCostsQuery from 'Query/SimulateShippingCosts.query';
import { fetchMutation, QueryDispatcher } from 'Util/Request';

import {
    setStoreList, setStoresList, storesRequestSent, toggleStoresDropdown
} from './Stores.action';
/** @namespace Bodypwa/Store/Stores/Dispatcher */
export class StoresDispatcher extends QueryDispatcher {
    onSuccess(data, dispatch) {
        const { configuredCountries } = data;
        if (configuredCountries) {
            dispatch(setStoresList(configuredCountries.countries));
        } else {
            dispatch(setStoresList([]));
        }
    }

    prepareRequest() {
        return ConfiguredCountries.getQuery();
    }

    async getSimulateShippingCosts(dispatch, options = {}) {
        const { address = {}, store_id } = options;
        try {
            const data = await fetchMutation(
                SimulateShippingCostsQuery.getSimulateShippingCostsMutation(address, store_id)
            );

            return data;
        } catch (error) {
            return Promise.reject();
        }
    }

    toggleStoreSelector(isOpened, dispatch) {
        dispatch(toggleStoresDropdown(isOpened));
    }

    storesRequestSent(isLoading, isComplete, dispatch) {
        dispatch(storesRequestSent(isLoading, isComplete));
    }

    setStoreList(storeList, dispatch) {
        dispatch(setStoreList(storeList));
    }
}

export default new StoresDispatcher();
