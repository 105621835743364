/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';

/** @namespace Paypal/Store/PaymentMethods/Action/setPaymentMethods */
export const setPaymentMethods = ({ getPaymentMethods = [] }) => ({
    type: SET_PAYMENT_METHODS,
    methods: getPaymentMethods
});
