/* eslint-disable max-lines */

import { SORT_DIRECTION_TYPE } from 'Route/CategoryPage/CategoryPage.config';
import { BEST_MATCH_SORT_OPTION_VALUE, NONE_SORT_OPTION_VALUE } from 'Route/SearchPage/SearchPage.config';
import {
    ProductListQuery as SourceProductListQuery
} from 'SourceQuery/ProductList.query';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';
import { Field, Fragment } from 'Util/Query';
/** @namespace Bodypwa/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    _getProductAltField() {
        return new Field('alt_image').addFieldList(this._getProductThumbnailFields());
    }

    _getProductSwatchesImagesField() {
        return new Field('alt_image').addFieldList(this._getProductThumbnailFields());
    }

    _getItemsField() {
        const items = new Field('items')
            .addFieldList(this._getProductInterfaceFields());

        items.addField(this._getProductAltField());

        return items;
    }

    _getVariantsField() {
        const { isPlp = false, notRequireInfo = false, isWidget = false } = this.options;

        // For PLP page we have optimized variants graphql field
        const variantsField = isPlp || notRequireInfo || isWidget ? 'variants_plp' : 'variants';

        return new Field(variantsField)
            .setAlias('variants')
            .addFieldList(this._getVariantFields());
    }

    _getConfigurableProductFragmentFields() {
        const { isPlp = false, notRequireInfo = false, isWidget = false } = this.options;

        if (isPlp || notRequireInfo || isWidget) {
            return [
                this._getConfigurableOptionsField()
            ];
        }

        return [
            this._getConfigurableOptionsField()
        ];
    }

    _getProductFields() {
        const { requireInfo, isSingleProduct, notRequireInfo } = this.options;

        // do not request total count for PDP
        if (isSingleProduct || notRequireInfo) {
            return [
                this._getItemsField(isSingleProduct)
            ];
        }

        // for filters only request
        if (requireInfo) {
            return [
                this._getSortField(),
                this._getAggregationsField()
            ];
        }

        return [
            'total_count',
            this._getItemsField(),
            this._getPageInfoField()
        ];
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
        const {
            isPlp = false,
            isWidget = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false,
            notRequireInfo = false
        } = this.options;

        // Basic fields returned always
        const fields = [
            'uid',
            'id',
            'sku',
            'name',
            'brand_name',
            'meta_title',
            'type_id',
            'jewelry_type',
            'customs_code',
            'campaign_label',
            'product_label_usp'
        ];

        if (!isPlp && !notRequireInfo && !isWidget) {
            fields.push(
                'stock_status',
                'salable_qty',
                this._getStockItemField(),
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date'
            );
        }

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant && notRequireInfo && isWidget)) {
            fields.push(
                this._getProductSmallField(),
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        // TODO add this for attributes   if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            // TODO large impact, REQUIRED FOR SWATCHE
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getUrlRewritesFields(),
            );

            if (!isPlp && !notRequireInfo && !isWidget) {
                fields.push(
                    this._getReviewCountField(),
                    this._getRatingSummaryField(),
                    this._getCustomizableProductFragment()
                );
            }

            if ((!noVariants && (isPlp || notRequireInfo || isWidget))) {
                // TODO small impact, REQUIRED FOR SWATCHE
                fields.push(
                    this._getConfigurableProductFragment()
                );
            } else if (!noVariants) {
                // if variants are not needed
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment(),
                    this._getGroupedProductItems()
                );
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment()
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    'canonical_url',
                    'meta_title',
                    'meta_keyword',
                    'meta_description',
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment(),
                    this._getProductLinksField()
                );
            }
        }

        return fields;
    }

    _getCartProductInterfaceFields() {
        return [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'url',
            'salable_qty',
            'original_price',
            'special_price',
            this._getStockItemField(),
            this._getProductThumbnailField(),
            this._getCartConfigurableProductFragment(),
            this._getAttributesField(false, true),
            this._getProductLinksField(),
            this._getPriceRangeField()
        ];
    }

    _getAttributeFields(isVariant = false, isCart = false) {
        return [
            'attribute_id',
            'attribute_value',
            'attribute_code',
            'attribute_label',
            'update_product_preview_image',
            ...this._getAdditionalAttributeFields(isCart),
            ...this._getAttributeOptionsFields(isVariant)
        ];
    }

    _getAggregationsFields() {
        return [
            new Field('label').setAlias('name'),
            new Field('attribute_code').setAlias('request_var'),
            'is_boolean',
            'has_swatch',
            'position',
            new Field('amshopby_filter_data')
                .addFieldList([
                    'visible_in_categories',
                    'categories_filter'
                ]),
            this._getAggregationsOptionsField()
        ];
    }

    _getArgumentsMap() {
        const { requireInfo } = this.options;
        const filterArgumentMap = this._getFilterArgumentMap();

        return {
            currentPage: { type: 'Int!' },
            pageSize: {
                type: 'Int!',
                handler: (option) => (requireInfo ? 1 : option)
            },
            search: {
                type: 'String!',
                handler: (option) => option.replace(/\+/g, ' ')
            },
            sort: {
                type: 'ProductAttributeSortInput!',
                handler: ({ sortKey, sortDirection }) => {
                    if (sortKey === NONE_SORT_OPTION_VALUE) {
                        return {};
                    }

                    if (sortKey === BEST_MATCH_SORT_OPTION_VALUE) {
                        return { [sortKey]: SORT_DIRECTION_TYPE.desc };
                    }

                    return { [sortKey]: sortDirection || SORT_DIRECTION_TYPE.asc };
                }
            },
            filter: {
                type: 'ProductAttributeFilterInput!',
                handler: (initialOptions = {}) => {
                    // add customer group by default to all requests
                    const { group_id } = BrowserDatabase.getItem(CUSTOMER) || {};

                    const options = {
                        ...initialOptions,
                        customerGroupId: group_id || '0'
                    };

                    const {
                        customFilters: { category_id } = {}
                    } = options;

                    /**
                     * Remove category ID from select, if there is a custom filter
                     * of category already selected in filtering options.
                     */
                    if (category_id) {
                        // eslint-disable-next-line fp/no-delete
                        options.categoryIds = undefined;
                    }

                    const parsedOptions = Object.entries(options).reduce(
                        (acc, [key, option]) => {
                            // if there is no value, or if the key is just not present in options object
                            if (!option || !filterArgumentMap[key]) {
                                return acc;
                            }

                            return { ...acc, ...filterArgumentMap[key](option) };
                        },
                        {}
                    );

                    return parsedOptions;
                }
            }
        };
    }/**
     * @returns {[string]} an array representing the subfields of the product thumbnail
     * @private
     */

    _getProductThumbnailFields() {
        return [
            'path',
            'url',
            'image_cover'
        ];
    }

    _getMediaGalleryFields() {
        return [
            'id',
            'file',
            'label',
            'position',
            'disabled',
            'media_type',
            'types',
            'image_cover',
            this._getVideoContentField(),
            this._getMediaThumbnailField(),
            this._getMediaBaseField(),
            this._getMediaLargeField()
        ];
    }

    /**
     * Returns a field querying the thumbnail of a media gallery entry.
     * Not to be confused with the product thumbnail field, which has the same name but is a direct subfield of the product
     * @returns {Field}
     * @private
     */
    // TODO add 'image_cover' once BE updates logic
    _getMediaThumbnailField() {
        return new Field('thumbnail').addFieldList(['url']);
    }

    _getMediaBaseField() {
        return new Field('base').addFieldList(['url']);
    }

    _getMediaLargeField() {
        return new Field('large').addFieldList(['url']);
    }

    _getProductArguments() {
        const { args } = this.options;
        const argumentMap = this._getArgumentsMap();

        return Object.entries(args).reduce((acc, [key, arg]) => {
            if (!arg) {
                return acc;
            }
            const { type, handler = (option) => option } = argumentMap[key];

            return [...acc, [key, type, handler(arg)]];
        }, []);
    }

    _getCartConfigurableProductFragment() {
        return new Fragment('OptimizedConfigurableProduct')
            .addFieldList([
                this._getConfigurableOptionsField(),
                this._getCartVariantsField()
            ]);
    }

    getReviewsQuery(options, reviewsPageLimit, reviewsNextPage) {
        if (!options) {
            throw new Error('Missing argument `options`');
        }

        this.options = options;

        const products = new Field('products')
            .addFieldList(
                [
                    new Field('items').addFieldList(
                        [
                            this._getReviewsFieldPaginated(reviewsPageLimit, reviewsNextPage)
                        ]
                    )
                ]
            );

        this._getProductArguments().forEach((arg) => products.addArgument(...arg));

        return products;
    }

    _getReviewsFieldPaginated(reviewsPageLimit, reviewsNextPage) {
        return new Field('reviews')
            // Hard-coded pages, it will be very hard to
            // paginate using current implementation
            // eslint-disable-next-line no-magic-numbers
            .addArgument('pageSize', 'Int', reviewsPageLimit)
            .addArgument('currentPage', 'Int', reviewsNextPage)
            .addFieldList(this._getReviewsFields());
    }

    _getReviewItemsFields() {
        return [
            'average_rating',
            'nickname',
            new Field('summary').setAlias('title'),
            new Field('text').setAlias('detail'),
            'created_at',
            this._getRatingsBreakdownField(),
            new Field('original')
                .addFieldList([
                    new Field('summary').setAlias('title'),
                    new Field('text').setAlias('detail'),
                    new Field('review_locale').setAlias('review_locale'),
                    new Field('store_url').setAlias('store_url')
                ]),
            'review_locale'
        ];
    }
}

export default new ProductListQuery();
