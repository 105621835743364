/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import Field from 'SourceUtil/Query/Field';

/**
 * GdprCookie Query
 * @class GdprCookieQuery
 * @namespace GdprCookie/Query/GdprCookie/Query */
export class GdprCookieQuery {
    getCookieGroups() {
        return new Field('getCookieGroups')
            .setAlias('cookieGroups')
            .addFieldList(this._getCookieGroupFields());
    }

    _getCookieGroupFields = () => ([
        'groupId',
        'isEssential',
        'name',
        'description',
        this._getCookieField()
    ]);

    _getCookieField = () => new Field('cookies')
        .addFieldList(this._getCookieFields());

    _getCookieFields = () => ([
        'name',
        'description',
        'lifetime'
    ]);

    getCookieSettings() {
        return new Field('getCookieSettings')
            .setAlias('gdprCookieSettings')
            .addFieldList(this._getCookieSettingFields());
    }

    _getCookieSettingFields = () => ([
        'gdpr_cookie_first_visit',
        'gdpr_cookie_bar_text',
        'gdpr_cookie_lifetime',
        'gdpr_cookie_decline_enabled'
    ]);

    setAllCookies() {
        return new Field('setAllCookies')
            .addFieldList(this._getCookiesSetResponse());
    }

    setSelectedCookies(selectedGroups) {
        return new Field('setSelectedCookies')
            .addArgument('selectedGroups', '[String]!', selectedGroups)
            .addFieldList(this._getCookiesSetResponse());
    }

    _getCookiesSetResponse = () => ([
        'result',
        'message'
    ]);
}

export default new GdprCookieQuery();
