import { Field } from 'Util/Query';

/** @namespace GtmNew/Query/GtmPurchase/Query */
export class GtmPurchaseQuery {
    getGTMPurchase(orderID = '') {
        return new Field('getPurchase')
            .setAlias('purchase')
            .addArgument('orderID', 'String', orderID)
            .addFieldList(this.getGTMPurchaseFields())
            .addField(this.getProductField());
    }

    getProductField() {
        return new Field('purchaseProducts')
            .setAlias('products')
            .addFieldList(this.getGTMPurchaseProductFields());
    }

    getGTMPurchaseProductFields() {
        return [
            'name',
            'id',
            'price',
            'quantity',
            'category',
            'dimension1',
            'dimension2'
        ];
    }

    getGTMPurchaseFields() {
        return [
            'orderPaymentMethod',
            'orderShippingMethod',
            'revenue',
            'tax',
            'shipping',
            'coupon'
        ];
    }
}
export default new GtmPurchaseQuery();
