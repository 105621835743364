import {
    CART_TOTALS as SOURCE_CART_TOTALS,
    CartReducer as sourceCartReducer,
    getInitialState as sourceGetInitialState,
    updateShippingPrice as sourceUpdateShippingPrice
} from 'SourceStore/Cart/Cart.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedProduct } from 'Util/Product';

import {
    CART_LOADING, RESET_SHIPPING_OPTIONS, UPDATE_SHIPPING_OPTIONS, UPDATE_TOTALS
} from './Cart.action';

export const CART_TOTALS = SOURCE_CART_TOTALS;

export const updateShippingPrice = sourceUpdateShippingPrice;

/** @namespace Bodypwa/Store/Cart/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    loading: false
});

/** @namespace Bodypwa/Store/Cart/Reducer/updateShippingOptions */
export const updateShippingOptions = (action, state) => {
    const {
        data: shippingOptions
    } = action;

    return {
        cartTotals: {
            ...state.cartTotals,
            shippingOptions
        }
    };
};
/** @namespace Bodypwa/Store/Cart/Reducer/updateCartTotals */
export const updateCartTotals = (action, state) => {
    const { cartData, cartData: { items = [], items_qty, ...rest } = {} } = action;

    const cartTotals = {
        ...rest,
        items: [],
        // eslint-disable-next-line no-nested-ternary
        items_qty: items_qty && cartData.items ? items_qty : cartData?.items ? 0 : -1
    };

    if (items.length) {
        const normalizedItemsProduct = items.map((item) => {
            const { ...normalizedItem } = item;
            normalizedItem.product = item.product.attributes && item.product.attributes[0]
                ? getIndexedProduct(item.product, item.sku) : item.product;

            return normalizedItem;
        });

        cartTotals.items = normalizedItemsProduct;
    }
    BrowserDatabase.setItem(
        cartTotals,
        CART_TOTALS
    );
    const { cartTotals: { shippingOptions } } = state;
    return { cartTotals: { ...cartTotals, shippingOptions } };
};

/** @namespace Bodypwa/Store/Cart/Reducer/CartReducer */
export const CartReducer = (state = getInitialState(), action) => {
    const { loading: currentLoading } = state;
    switch (action.type) {
    case UPDATE_TOTALS:
        return {
            ...updateCartTotals(action, state),
            loading: currentLoading
        };
    case UPDATE_SHIPPING_OPTIONS:
        return {
            ...updateShippingOptions(action, state),
            loading: currentLoading
        };
    case CART_LOADING:
        const { loading } = action;
        return {
            ...state,
            loading
        };
    case RESET_SHIPPING_OPTIONS:
        return {
            ...state,
            cartTotals: {
                ...state.cartTotals,
                shippingOptions: []
            }
        };
    default:
        return sourceCartReducer(state, action);
    }
};

export default CartReducer;
