import { META_NOINDEX_FOLLOW, META_NOINDEX_FOLLOW_PAGES } from 'Component/Meta/Meta.config';
import {
    filterData as sourceFilterData,
    getInitialState as sourceGetInitialState,
    updateEveryTime as sourceUpdateEveryTime
} from 'SourceStore/Meta/Meta.reducer';
import history from 'Util/History';

import { UPDATE_META } from './Meta.action';

// TODO: implement updateEveryTime
export const updateEveryTime = sourceUpdateEveryTime;

// TODO: implement filterData
export const filterData = sourceFilterData;

// TODO: implement getInitialState
export const getInitialState = sourceGetInitialState;

/** @namespace Bodypwa/Store/Meta/Reducer/MetaReducer */
export const MetaReducer = (
    state = getInitialState(),
    action
) => {
    const { payload = {}, type } = action;

    switch (type) {
    case UPDATE_META:
        const filteredData = filterData(payload);
        const { isNoHreflangs } = payload;
        const { canonical_url, robots } = filteredData;
        const { location: { pathname } } = history;

        const noIndex = META_NOINDEX_FOLLOW_PAGES.filter((page) => pathname.indexOf(page) > -1).length > 0;

        return {
            ...state,
            ...filteredData,
            canonical_url: canonical_url && canonical_url.indexOf(window.location.origin) > -1
                ? `${canonical_url || pathname}` : `${window.location.origin}${canonical_url || pathname}`,
            robots: noIndex ? META_NOINDEX_FOLLOW : robots,
            isNoHreflangs
        };

    default:
        return state;
    }
};

export default MetaReducer;
