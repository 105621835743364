import {
    RESET_HEADER,
    SET_FILTER_ELEMENT,
    SET_FILTER_TOP_REF,
    SET_FILTERS_HEIGHT,
    SET_FOOTER_REF, SET_HEADER_ELEMENT,
    SET_HEADER_HEIGHT, SET_HEADER_HIDDEN,
    SET_HEADER_PLACEHOLDER_HEIGHT,
    SET_HEADER_POSITION,
    SET_HEADER_TOP_REF,
    SET_SCROLL_TO_ELEMENT, SET_SCROLL_TO_POSITION,
    SET_TOP_PANEL_POSITION, SET_WINDOW_HEIGHT, UPDATE_SCROLL
} from './Ui.action';

/** @namespace Bodypwa/Store/Ui/Reducer/getInitialState */
export const getInitialState = () => ({
    headerHidden: false,
    headerHeight: 0,
    footerRef: null,
    topPannelPosition: 0,
    scrollToElement: 0,
    headerPosition: 0,
    headerPlaceholderHeight: 0,
    headerElement: null,
    scrollToPosition: 0,
    updateScroll: false,
    isResetHeader: false,
    filtersHeight: 0
});

/** @namespace Bodypwa/Store/Ui/Reducer/UiReducer */
export const UiReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case SET_HEADER_HEIGHT:
        const { headerHeight } = action;

        return {
            ...state,
            headerHeight
        };
    case SET_HEADER_PLACEHOLDER_HEIGHT:
        const { headerPlaceholderHeight } = action;

        return {
            ...state,
            headerPlaceholderHeight
        };
    case SET_FOOTER_REF:
        const { footerRef } = action;
        return {
            ...state,
            footerRef
        };
    case SET_TOP_PANEL_POSITION:
        const { topPannelPosition } = action;
        return {
            ...state,
            topPannelPosition
        };
    case SET_SCROLL_TO_ELEMENT:
        const { scrollToElement } = action;
        return {
            ...state,
            scrollToElement
        };
    case SET_WINDOW_HEIGHT:
        const { windowHeight } = action;
        document.documentElement.style.setProperty('--window-height', `${windowHeight}px`);
        return {
            ...state,
            windowHeight
        };
    case SET_HEADER_HIDDEN:
        const { headerHidden } = action;
        return {
            ...state,
            headerHidden
        };
    case SET_HEADER_POSITION:
        const { headerPosition } = action;
        return {
            ...state,
            headerPosition
        };
    case SET_SCROLL_TO_POSITION:
        const { scrollToPosition } = action;
        return {
            ...state,
            scrollToPosition
        };
    case UPDATE_SCROLL:
        const { updateScroll } = action;
        return {
            ...state,
            updateScroll
        };
    case SET_FILTERS_HEIGHT:
        const { filtersHeight } = action;
        return {
            ...state,
            filtersHeight
        };
    case SET_HEADER_ELEMENT:
        const { headerElement } = action;
        return {
            ...state,
            headerElement
        };
    case SET_HEADER_TOP_REF:
        const { headerTopRef } = action;
        return {
            ...state,
            headerTopRef
        };
    case SET_FILTER_ELEMENT:
        const { filterElement } = action;
        return {
            ...state,
            filterElement
        };
    case SET_FILTER_TOP_REF:
        const { filterTopRef } = action;
        return {
            ...state,
            filterTopRef
        };
    case RESET_HEADER:
        const { resetHeader } = action;
        return {
            ...state,
            resetHeader
        };
    default:
        return state;
    }
};

export default UiReducer;
