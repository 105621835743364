/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace LayoutUpdates/Query/Widget/Query */
export class WidgetQuery {
    getQuery({ widgetId }) {
        return new Field('widget')
            .addArgument('id', 'String!', widgetId)
            .addFieldList(this.getChildFields());
    }

    getChildFields() {
        return [
            'id',
            'title',
            'type',
            'params',
            'sort_order'
        ];
    }
}

export default new WidgetQuery();
