import {
    INCREMENT_ROUTE_COUNTER, RESET_ROUTE_COUNTER,
    SET_MENU, SET_TIME_START
} from './Menu.action';

export const initialState = {
    menu: {},
    routeChangeCounter: 0,
    timeStart: 0
};

/** @namespace Bodypwa/Store/Menu/Reducer/MenuReducer */
export const MenuReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_MENU:
        return {
            ...state,
            menu: action.menu
        };
    case INCREMENT_ROUTE_COUNTER:
        const newCounter = state.routeChangeCounter + 1;
        return {
            ...state,
            routeChangeCounter: newCounter
        };
    case RESET_ROUTE_COUNTER:
        return {
            ...state,
            routeChangeCounter: 0
        };
    case SET_TIME_START:
        return {
            ...state,
            timeStart: action.timeStart
        };
    default:
        return state;
    }
};

export default MenuReducer;
