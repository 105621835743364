/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import { SET_PAYMENT_METHODS } from './PaymentMethods.action';

/** @namespace Paypal/Store/PaymentMethods/Reducer/getInitialState */
export const getInitialState = () => ({
    methods: []
});

/** @namespace Paypal/Store/PaymentMethods/Reducer/PaymentMethodsReducer */
export const PaymentMethodsReducer = (
    state = getInitialState(),
    action
) => {
    const { methods: stateMethods } = state;
    const { type, methods } = action;

    switch (type) {
    case SET_PAYMENT_METHODS:
        return {
            methods: [
                ...stateMethods,
                ...methods
            ]
        };
    default:
        return state;
    }
};

export default PaymentMethodsReducer;
