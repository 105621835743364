import { lazy } from 'react';

/** @namespace Bodypwa/Util/LazyComponentLoader/lazyComponentLoader */
export const lazyComponentLoader = (componentImport) => lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
        window.localStorage.getItem(
            'cache-cleared-refreshed'
        ) || 'false'
    );

    try {
        const component = await componentImport();

        window.localStorage.setItem(
            'cache-cleared-refreshed',
            'false'
        );

        return component;
    } catch (error) {
        if (!pageHasAlreadyBeenForceRefreshed) {
            if ('caches' in window) {
                caches
                    .keys()
                    .then(
                        /** @namespace Bodypwa/Util/LazyComponentLoader/lazyComponentLoader/lazy/lazy/then/then/keys/then */
                        (cacheNames) => {
                            const deletePromises = cacheNames.map((cacheName) => caches.delete(cacheName));
                            window.localStorage.setItem(
                                'cache-cleared-refreshed',
                                'true'
                            );

                            return Promise.all(deletePromises);
                        }
                    )
                    .then(
                        /** @namespace Bodypwa/Util/LazyComponentLoader/lazyComponentLoader/lazy/lazy/then/then */
                        () => window.location.reload()
                    );
            }

            return window.location.reload();
        }
        throw error;
    }
});
