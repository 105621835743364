import {
    HIDE_NOTIFICATION as SOURCE_HIDE_NOTIFICATION,
    hideNotification as sourceHideNotification,
    SHOW_NOTIFICATION as SOURCE_SHOW_NOTIFICATION,
    showNotification as sourceShowNotification
} from 'SourceStore/Notification/Notification.action';

// TODO: implement SHOW_NOTIFICATION
export const SHOW_NOTIFICATION = SOURCE_SHOW_NOTIFICATION;

// TODO: implement HIDE_NOTIFICATION
export const HIDE_NOTIFICATION = SOURCE_HIDE_NOTIFICATION;

// TODO: implement showNotification
export const showNotification = sourceShowNotification;

// TODO: implement hideNotification
export const hideNotification = sourceHideNotification;
