import {
    CheckoutQuery as SourceCheckoutQuery
} from 'SourceQuery/Checkout.query';

/** @namespace Bodypwa/Query/Checkout/Query */
export class CheckoutQuery extends SourceCheckoutQuery {
    _getEstimatedShippingFields() {
        return [
            'amount',
            'available',
            'base_amount',
            'method_code',
            'carrier_code',
            'method_title',
            'carrier_title',
            'error_message',
            'price_excl_tax',
            'price_incl_tax',
            'description',
            'shipping_method_type_detail'
        ];
    }

    _getPaymentMethodFields() {
        return ['code', 'title', 'fee'];
    }
}

export default new CheckoutQuery();
