export const POSTNORD_SET_POINT = 'POSTNORD_SET_POINT';
export const POSTNORD_SET_ADDRESS_POINT = 'POSTNORD_SET_ADDRESS_POINT';
export const POSTNORD_SET_SLECTED_POINT = 'POSTNORD_SET_SLECTED_POINT';
export const RESET_POSTNORD = 'RESET_POSTNORD';

/** @namespace Bodymod/PostNord/Store/PostNord/Action/setPostNordPoint */
export const setPostNordPoint = (point) => ({
    type: POSTNORD_SET_POINT,
    point
});

/** @namespace Bodymod/PostNord/Store/PostNord/Action/setPostNordAddressPoints */
export const setPostNordAddressPoints = (points) => ({
    type: POSTNORD_SET_ADDRESS_POINT,
    points
});

/** @namespace Bodymod/PostNord/Store/PostNord/Action/setPostNordSelectedPoint */
export const setPostNordSelectedPoint = (selectedPoint) => ({
    type: POSTNORD_SET_SLECTED_POINT,
    selectedPoint
});

/** @namespace Bodymod/PostNord/Store/PostNord/Action/resetPostNord */
export const resetPostNord = () => ({
    type: RESET_POSTNORD
});
