export const altCodes = {
    amex: 'AE',
    bcmc: 'BCMC',
    cup: 'UN',
    diners: 'DN',
    discover: 'DI',
    hipercard: 'HIPERCARD',
    jcb: 'JCB',
    maestro: 'MI',
    mc: 'MC',
    visa: 'VI'
};

/** @namespace AdyenPayment/Util/GetCcCodeByAltCode/Util/getCcCodeByAltCodeUtil */
export const getCcCodeByAltCodeUtil = (altCode = 'visa') => altCodes[altCode];
