import {
    UPDATE_META as SOURCE_UPDATE_META,
    updateMeta as sourceUpdateMeta
} from 'SourceStore/Meta/Meta.action';

// TODO: implement UPDATE_META
export const UPDATE_META = SOURCE_UPDATE_META;

// TODO: implement updateMeta
export const updateMeta = sourceUpdateMeta;
