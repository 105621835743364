export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';

export const UPDATE_PRODUCT_PRICE = 'UPDATE_PRODUCT_PRICE';

export const UPDATE_PRODUCT_VARIANTS = 'UPDATE_PRODUCT_VARIANTS';

export const UPDATE_CURRENT_PRODUCT_IMAGE = 'UPDATE_CURRENT_PRODUCT_IMAGE';

export const TOGGLE_OPTIONS = 'TOGGLE_OPTIONS';

export const UPDATE_PRODUCT_DETAILS = 'UPDATE_PRODUCT_DETAILS';

/**
 * Update product list with new list (rewrite if already exists).
 * @param  {Array<Object>} items List of products returned from fetch
 * @param  {Number} totalItems Total number of products in this filter
 * @return {void}
 * @namespace Bodypwa/Store/Product/Action/updateProductDetails */
export const updateProductDetails = (product) => ({
    type: UPDATE_PRODUCT_DETAILS,
    product
});

/** @namespace Bodypwa/Store/Product/Action/updateProduct */
export const updateProduct = (productAddtionalData) => ({
    type: UPDATE_PRODUCT,
    productAddtionalData
});

/** @namespace Bodypwa/Store/Product/Action/updateProductPrice */
export const updateProductPrice = (productAddtionalData) => ({
    type: UPDATE_PRODUCT_PRICE,
    productAddtionalData
});

/** @namespace Bodypwa/Store/Product/Action/updateProductVariants */
export const updateProductVariants = (productAddtionalData) => ({
    type: UPDATE_PRODUCT_VARIANTS,
    productAddtionalData
});

/** @namespace Bodypwa/Store/Product/Action/updateCurrentProductImage */
export const updateCurrentProductImage = (productAddtionalData) => ({
    type: UPDATE_CURRENT_PRODUCT_IMAGE,
    productAddtionalData
});

/** @namespace Bodypwa/Store/Product/Action/toggleOptions */
export const toggleOptions = (showOptions) => ({
    type: TOGGLE_OPTIONS,
    showOptions
});
