export const TOGGLE_STORES_DROPDOWN = 'TOGGLE_STORES_DROPDOWN';
export const STORES_REQUEST_SENT = 'STORES_REQUEST_SENT';
export const SET_STORE_LIST = 'SET_STORE_LIST';
export const SET_STORES_LIST = 'SET_STORES_LIST';
/** @namespace Bodypwa/Store/Stores/Action/toggleStoresDropdown */
export const toggleStoresDropdown = (isOpened) => ({
    type: TOGGLE_STORES_DROPDOWN,
    isOpened
});

/** @namespace Bodypwa/Store/Stores/Action/storesRequestSent */
export const storesRequestSent = (isLoading, isComplete) => ({
    type: STORES_REQUEST_SENT,
    isLoading,
    isComplete
});

/** @namespace Bodypwa/Store/Stores/Action/setStoreList */
export const setStoreList = (storeList) => ({
    type: SET_STORE_LIST,
    storeList
});

/** @namespace Bodypwa/Store/Stores/Action/setStoresList */
export const setStoresList = (storesList) => ({
    type: SET_STORES_LIST,
    storesList
});
