import {
    RESET_CMS_BLOCKS,
    SET_CMS_BLOCK
} from './Cms.action';

export const initialState = {
    blocks: {}
};

/** @namespace Bodypwa/Store/Cms/Reducer/CmsReducer */
export const CmsReducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_CMS_BLOCK:
        const { block: { content, identifier } } = action;
        return {
            ...state,
            blocks: {
                ...state.blocks,
                [identifier]: content
            }
        };
    case RESET_CMS_BLOCKS:
        return {
            ...state,
            blocks: {}
        };
    default:
        return state;
    }
};

export default CmsReducer;
