import { Field } from 'Util/Query';

/** @namespace Bodypwa/Query/SimulateShippingCosts/Query */
export class SimulateShippingCostsQuery {
    getSimulateShippingCostsMutation(address, store_id) {
        return new Field('simulateShippingCosts')
            .addArgument('address', 'EstimateShippingCostsAddress!', address)
            .addArgument('store_id', 'Int!', store_id)
            .addFieldList(this._getSimulateShippingCostsFields());
    }

    _getSimulateShippingCostsFields() {
        return [
            'amount',
            'available',
            'base_amount',
            'method_code',
            'carrier_code',
            'method_title',
            'carrier_title',
            'error_message',
            'price_excl_tax',
            'price_incl_tax',
            'description'
        ];
    }
}

export default new SimulateShippingCostsQuery();
