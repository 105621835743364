import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import history from 'Util/History';

import {
    ENTRY_POINT_ARRAY,
    FIREBASE_ENDPOINT_GTM
} from './Gtm.config';

/** @namespace Bodypwa/Util/Gtm/detectBrowser */
export const detectBrowser = () => {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) {
            return { name: tem.slice(1).join(' ').replace('OPR', 'Opera'), version: tem[2] };
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    // eslint-disable-next-line no-cond-assign
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }

    return {
        name: M[0],
        version: M[1]
    };
};
/** @namespace Bodypwa/Util/Gtm/isFunction */
export const isFunction = (functionToCheck) => functionToCheck
&& {}.toString.call(functionToCheck) === '[object Function]';

/** @namespace Bodypwa/Util/Gtm/logEventData */
export const logEventData = (eventData = { entry: false, clearAndLog: false }) => {
    const { eventDetails, entry, clearAndLog } = eventData;
    const { location } = history;
    const currentDate = new Date(Date.now()).toString();
    const size = {
        winWidth: `${window.innerWidth}px`,
        winHeight: `${window.innerHeight}px`
    };
    const data = {
        entry,
        currentDate,
        browser: detectBrowser(),
        eventDetails,
        location,
        baseUrl: window.location.origin,
        windowSession: sessionStorage.getItem('WINDOW_ID'),
        size
    };

    if (entry && ENTRY_POINT_ARRAY.includes(entry)) {
        BrowserDatabase.setItem(data, entry);
    }

    const dataArray = [];
    if (clearAndLog) {
        ENTRY_POINT_ARRAY.forEach((entryPoint) => {
            const entryData = BrowserDatabase.getItem(entryPoint);
            if (entryData) {
                dataArray.push(entryData);
                BrowserDatabase.deleteItem(entryPoint);
            }
        });
        fetch(FIREBASE_ENDPOINT_GTM, {
            method: 'POST',
            body: JSON.stringify(dataArray),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(
            /** @namespace Bodypwa/Util/Gtm/logEventData/then/catch/then/then/fetch/then */
                (response) => response.json()
            )
            .then(
            /** @namespace Bodypwa/Util/Gtm/logEventData/then/catch/then/then */
                () => {
                    // SUCCESS LOGIC IF REQUIRED
                }
            )
            .catch(
            /** @namespace Bodypwa/Util/Gtm/logEventData/then/catch */
                (error) => {
                    console.error('Error:', error);
                }
            );
    }
};
