/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { HIDE_ACTIVE_POPUP } from 'Store/Overlay/Overlay.action';

export const SHOW_POPUP = 'SHOW_POPUP';

/** @namespace Bodypwa/Store/Popup/Action/closePopup */
export const closePopup = (overlayKey, payload) => ({
    type: HIDE_ACTIVE_POPUP,
    overlayKey,
    payload: { [overlayKey]: payload }
});

/** @namespace Bodypwa/Store/Popup/Action/showPopup */
export const showPopup = (overlayKey, payload) => ({
    type: SHOW_POPUP,
    overlayKey,
    payload: { [overlayKey]: payload }
});
