/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package @scandipwa/paypal
 */

import {
    PAYPAL_RESET_STATE,
    PAYPAL_STATE
} from './PayPalPayment.action';

/** @namespace Paypal/Store/PayPalPayment/Reducer/getInitialState */
export const getInitialState = () => ({
    token: '',
    payerID: '',
    isLoading: false,
    guest_cart_id: '',
    widgetLocation: '',
    selectedCarrier: {},
    billing_address: {},
    shipping_address: {},
    shipping_methods: [],
    isActiveInstant: false,
    selectedAddressId: null,
    isCustomAddressExpanded: false,
    customer: {
        firstname: '',
        lastname: '',
        telephone: '',
        email: ''
    }
});

/** @namespace Paypal/Store/PayPalPayment/Reducer/getInitialReducerState */
export const getInitialReducerState = () => ({
    state: getInitialState()
});

/** @namespace Paypal/Store/PayPalPayment/Reducer/PayPalPaymentReducer */
export const PayPalPaymentReducer = (
    state = getInitialReducerState(),
    action
) => {
    const { state: currentState } = state;
    const { type, state: actionState } = action;

    switch (type) {
    case PAYPAL_RESET_STATE:
        return {
            ...state,
            state: {
                ...getInitialState()
            }
        };
    case PAYPAL_STATE:
        return {
            ...state,
            state: {
                ...currentState,
                ...actionState
            }
        };
    default:
        return state;
    }
};

export default PayPalPaymentReducer;
