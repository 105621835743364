export const SET_BLOG_DATA = 'SET_BLOG_DATA';
export const SET_BLOG_LOADING_STATUS = 'SET_BLOG_LOADING_STATUS';

/**
 * Set blog data in the store based on the query type.
 * @param {String} queryType Type of the query used to fetch the data
 * @param {Object} data Data returned from the query
 * @param {Object} args Arguments used for the query
 * @namespace Bodypwa/Store/Blog/Action/setBlogData */
export const setBlogData = (data, args) => ({
    type: SET_BLOG_DATA,
    data,
    args
});

/**
 * Set the loading status for blog data fetching.
 * @param {Boolean} isLoading Loading indication boolean
 * @namespace Bodypwa/Store/Blog/Action/setBlogLoadingStatus */
export const setBlogLoadingStatus = (isLoading) => ({
    type: SET_BLOG_LOADING_STATUS,
    isLoading
});
