import {
    MenuQuery as SourceMenuQuery
} from 'SourceQuery/Menu.query';

/** @namespace Bodypwa/Query/Menu/Query */
export class MenuQuery extends SourceMenuQuery {
    _getMenuItemFields() {
        return [
            'url',
            'icon',
            'image',
            'title',
            'item_id',
            'position',
            'url_type',
            'parent_id',
            'is_active',
            'item_class',
            'category_id',
            'cms_page_identifier'
        ];
    }
}

export default new MenuQuery();
