/** @namespace Bodypwa/Util/Variants/mergeVariants */
export const mergeVariants = (items, initialVaraints) => {
    const mergedItems = [];
    for (let i = 0; i < items.length; i++) {
        const item = initialVaraints.find((itmInner) => itmInner.sku === items[i].sku);
        mergedItems.push({
            ...items[i],
            variants: item ? item.variants : []
        });
    }

    return { mergedItems };
};

/** @namespace Bodypwa/Util/Variants/mergeItemsPrice */
export const mergeItemsPrice = (items, itemsWithPrice) => {
    const mergedItems = [];
    for (let i = 0; i < items.length; i++) {
        const item = itemsWithPrice.find((itmInner) => itmInner.sku === items[i].sku);
        if (item) {
            mergedItems.push({
                ...items[i],
                price_range: item.price_range
            });
        }
    }

    return { mergedItems };
};

/** @namespace Bodypwa/Util/Variants/mergeProducts */
export const mergeProducts = (items, itemsWithVariants, page) => {
    const mergedItems = [];
    if (!items) {
        return { mergedItems: itemsWithVariants };
    }
    for (let i = 0; i < items.length; i++) {
        const item = itemsWithVariants.find((itmInner) => itmInner.sku === items[i].sku);
        if (item) {
            mergedItems.push({
                ...items[i],
                ...item,
                attributes: Object.keys(items[i].attributes).length !== 0 ? items[i].attributes : item.attributes || [],
                configurable_options: Object.keys(items[i].configurable_options).length !== 0
                    ? items[i].configurable_options
                    : item.configurable_options || {},
                variants: item.variants?.length ? item.variants : items[i].variants || [],
                price_range: item.price_range?.minimum_price ? item.price_range : items[i].price_range,
                page: page || 1
            });
        } else {
            mergedItems.push({ ...items[i], page: page || 1 });
        }
    }

    return { mergedItems };
};

/** @namespace Bodypwa/Util/Variants/mergeItems */
export function mergeItems(array1, array2) {
    return array1.map((item, index) => ({
        ...item,
        ...array2[index]
    }));
}

/** @namespace Bodypwa/Util/Variants/isEqual */
export function isEqual(obj1, obj2) {
    if (obj1 === obj2) {
        return true;
    }
    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return false;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys1) {
        if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}
