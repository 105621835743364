import {
    ADD_PRODUCT_TO_CART,
    addProductToCart,
    APPLY_COUPON_TO_CART,
    applyCouponToCart,
    REMOVE_COUPON_FROM_CART,
    REMOVE_PRODUCT_FROM_CART,
    removeCouponFromCart,
    removeProductFromCart,
    UPDATE_SHIPPING_PRICE,
    UPDATE_TOTALS,
    updateShippingPrice,
    updateTotals
} from 'SourceStore/Cart/Cart.action';

export {
    ADD_PRODUCT_TO_CART,
    REMOVE_PRODUCT_FROM_CART,
    UPDATE_TOTALS,
    APPLY_COUPON_TO_CART,
    REMOVE_COUPON_FROM_CART,
    UPDATE_SHIPPING_PRICE,
    addProductToCart,
    removeProductFromCart,
    updateTotals,
    applyCouponToCart,
    removeCouponFromCart,
    updateShippingPrice
};
export const UPDATE_SHIPPING_OPTIONS = 'UPDATE_SHIPPING_OPTIONS';

export const CART_LOADING = 'CART_LOADING';
export const RESET_SHIPPING_OPTIONS = 'RESET_SHIPPING_OPTIONS';

/** @namespace Bodypwa/Store/Cart/Action/updateShippingOptions */
export const updateShippingOptions = (data) => ({
    type: UPDATE_SHIPPING_OPTIONS,
    data
});

/** @namespace Bodypwa/Store/Cart/Action/setCartLoading */
export const setCartLoading = (loading) => ({
    type: CART_LOADING,
    loading
});

/** @namespace Bodypwa/Store/Cart/Action/resetShippingOptions */
export const resetShippingOptions = () => ({
    type: RESET_SHIPPING_OPTIONS
});
