import { deleteFromCache } from 'Util/deleteFromCache';

export default function componentLoader(lazyComponent, attemptsLeft) {
    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch(
                /** @namespace Bodypwa/Util/ComponentLoader/componentLoader/then/catch */
                async (error) => {
                // let us retry after 1500 ms
                    const errorString = error.toString();
                    const urlRegex = /error:\s(https:\/\/[^\s]+)/;
                    const urlMatch = errorString.match(urlRegex);

                    if (urlMatch && urlMatch[1]) {
                        const url = urlMatch[1];
                        const urlWithoutDomain = new URL(url).pathname;

                        // Delete the URL from the cache
                        await deleteFromCache(urlWithoutDomain);
                    }

                    setTimeout(() => {
                        if (attemptsLeft === 1) {
                            reject(error);
                            return;
                        }
                        componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
                    // eslint-disable-next-line no-magic-numbers
                    }, 1500);
                }
            );
    });
}
