import {
    STORE_SWITCHER_FOOTER_ID,
    STORE_SWITCHER_HEADER_ID
} from 'Component/StoreSwitcher/StoreSwitcher.config';

import {
    SET_STORE_LIST, SET_STORES_LIST, STORES_REQUEST_SENT, TOGGLE_STORES_DROPDOWN
} from './Stores.action';

export const isOpened = {};
isOpened[STORE_SWITCHER_HEADER_ID] = false;
isOpened[STORE_SWITCHER_FOOTER_ID] = false;
/** @namespace Bodypwa/Store/Stores/Reducer/getInitialState */
export const getInitialState = () => ({
    isOpened,
    isLoading: false,
    isComplete: false,
    storeList: [],
    countries: []
});

/** @namespace Bodypwa/Store/Stores/Reducer/StoresReducer */
export const StoresReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case TOGGLE_STORES_DROPDOWN:
        const { isOpened } = action;

        return {
            ...state,
            isOpened
        };
    case STORES_REQUEST_SENT:
        const { isLoading, isComplete } = action;

        return {
            ...state,
            isLoading,
            isComplete
        };
    case SET_STORE_LIST:
        const { storeList } = action;

        return {
            ...state,
            storeList
        };
    case SET_STORES_LIST:
        const { storesList } = action;

        return {
            ...state,
            countries: storesList
        };
    default:
        return state;
    }
};

export default StoresReducer;
