import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { Field } from 'Util/Query';

/** @namespace AdyenPayment/Query/Adyen/Query */
export class AdyenQuery {
    getPaymentMethods() {
        const isCustomerSignedIn = isSignedIn();
        const guest_cart_id = !isCustomerSignedIn ? getGuestQuoteId() : '';

        return new Field('getAdyenPaymentMethods')
            .addArgument('guestCartId', 'ID', guest_cart_id);
    }

    handleAdyenPaymentDetails(payload) {
        return new Field('adyenPaymentDetails')
            .addArgument('payload', 'String', payload);
    }

    getConfig() {
        return new Field('getAdyenConfig')
            .addField('originKey')
            .addField('mode')
            .addField('locale');
    }
}

export default new AdyenQuery();
